
.servland-header{
padding: 18px 0px;
position: fixed;
top: 0;
left: 0;
right: 0;
width: 100%;
z-index: 9;
transition: all 0.4s ease-out;
border-bottom: 1px solid transparent;
}

.servland-header .logo{
text-align: left;
}

.servland-header.stick{
    padding: 15px 0px;
    border-color: #000;
}

.servheader-icon{
display: flex;
align-items: center;
justify-content: end;
}

.servheader-icon li {
margin-left: 5px;
}

.servheader-icon li a{
display: flex;
align-items: center;
justify-content: center;
background: #000;
border-radius: 4px;
width: 35px;
height: 35px;
transition: all 0.2s ease-In;
}

.servheader-icon li a:hover{
background: #B7A9A5;
}

.servheader-icon li a img{
width: 15px;
transition: all 0.2s ease-In;
}

.servheader-icon li a:hover img{
-webkit-filter: brightness(0) saturate(100%);
filter: brightness(0) saturate(100%);
}


.servland-banner{
background: url(../public/assets/landing/servland-banner.webp) no-repeat;
background-size: cover;
position: relative;
z-index: 1;
padding: 152px 0px 60px;
}

.servland-banner:before {
content: '';
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
width: 100%;
height: 100%;
background: rgb(0 0 0 / 50%);
z-index: -1;
}

.servbanner-text .heading{
color: #fff;
text-transform: lowercase;
}

.landform-head{
color: rgb(0 0 0 / 50%);
font-size: 13px;
}

.landform-head a{
color: rgb(0 0 0 / 50%);
border-bottom: 1px solid rgb(0 0 0 / 50%);
transition: all 0.2s ease-In;
}

.landform-head a:hover{
color: rgb(0 0 0 / 80%);
border-color: rgb(0 0 0 / 80%);
}


.servland-counter{
background: #F2F0EF;  
}

.servland-counter .home-counter .row {
border: 0px;
}

.servland-counter .homecount-box{
margin: 30px 0px;
}

.servland-counter .homecount-box p {
font-size: 15px;
color: #B7A9A5;
font-family: Gilroy;
text-transform: uppercase;
font-weight: 500;
letter-spacing: 1px;
}

.servland-counter .counter-vector-1,
.servland-counter .counter-vector-2{
display: none;
}


.servland-about{
background: #F2F0EF;
padding: 60px 0px;
}

.servabout-detail{
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100%;
}

.servabout-detail .heading{
text-transform: lowercase;
margin: 12px 0px 10px;
}

.servabout-detail img {
width: 32px;
}

.servabout-detail p {
margin-bottom: 0px;
color: rgb(0 0 0 / 60%);
text-align: justify;
}

.servabout-detail .default-btn{
display: none;
}

.servabout-image{
position: relative;
padding-right: 40px;
}

.servabout-image .img-responsive {
border-radius: 10px;
}

.servabout-row{
background: #fff;
border-radius: 10px;
padding: 25px;
overflow: hidden;
}


.servland-service{
background: #F2F0EF;
padding: 60px 0px;
}

.serv-servicehead h4{
color: #000000;
text-transform: uppercase;
font-size: 32px;
font-weight: 600;
margin: 0px;
}

.serv-servicebox{
display: flex;
justify-content: space-between;
margin-top: 40px;
}

.serv-servicebox div{
background: #fff;
border-radius: 10px;
padding: 25px;
width: 24.5%;
text-align: center;
}

.serv-servicebox div img {
width: 32px;
}

.serv-servicebox div span {
font-size: 14px;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 1.2px;
display: block;
color: #000;
margin: 18px 0px 0px;
}

.serv-service-btn{
text-align: right;
}


.servland-gallery .facility-slider {
margin: 0px;
background: #F2F0EF;
}


.servland-why{
padding: 60px 0px;
}

.servland-why .heading {
color: #B7AAA4;
border-bottom: 1px solid #B7AAA4;
padding-bottom: 30px;
}

.servwhy-box{
margin-top: 33px;
}

.servwhy-box h4 {
color: #B7AAA4;
font-family: "Deluce";
line-height: 1;
font-size: 45px;
margin: 0px;
}

.servwhy-box span {
font-size: 16px;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 1.2px;
display: block;
color: #000;
margin: 14px 0px 10px;
}

.servwhy-box p {
margin: 0px;
color: rgb(0 0 0 / 60%);
text-align: justify;
}


.servtesti-row{
background: #000000;
border-radius: 15px;
padding: 40px;
border: 3px solid #B7AAA4;
}

.servtesti-mover .slick-list .slick-slide > div{
padding: 0px 5px;
}

.servtesti-slide{
background: #fff;
padding: 30px;
border-radius: 10px;
}

.servtesti-slide span {
color: #000000;
font-size: 17px;
display: block;
font-weight: 500;
}

.servtesti-slide p {
margin: 30px 0px 20px;
text-align: justify;
height: 126px;
overflow-y: auto;
padding-right: 12px;
}

.servtesti-slide p::-webkit-scrollbar {
width: 2px;
background: #F2F0EF;
}

.servtesti-slide p::-webkit-scrollbar-track {
-webkit-box-shadow: none;
}

.servtesti-slide p::-webkit-scrollbar-thumb {
background-color: #b7aaa4;
outline: none;
border-radius: 50px;
}


.servtesti-slide small {
color: #B7AAA4;
font-weight: 400;
text-transform: uppercase;
font-size: 12px;
}

.servtesti-slide img {
width: 42px;
}

.landtesti-arrows{
align-items: center;
display: flex;
justify-content: center;
margin-top: 40px;
}

.landtesti-arrows .default-arrowleft, 
.landtesti-arrows .default-arrowright {
border-radius: 5px;
border: 0px;
background: #B3A693;
color: #fff;
}

.landtesti-arrows .default-arrowleft:hover, 
.landtesti-arrows .default-arrowright:hover {
background: #fff;
color: #B3A693;
}


.servland-project{
background: linear-gradient(to top, #F2F0EF, rgb(255 255 255 / 0%) 50%);
padding: 50px 0px 60px;
}

.servland-project .center-heading{
margin-bottom: 40px;
}

.servland-projectbox{
position: relative;
border-radius: 10px;
overflow: hidden;
margin-bottom: 40px;
}

.servland-projectbox a {
display: block;
position: relative;
}

.servland-projectbox a:before {
content: '';
position: absolute;
width: 100%;
height: 100%;
left: 0;
right: 0;
bottom: 0;
background: linear-gradient(180deg, transparent, rgb(0 0 0 / 50%) 100%);
z-index: 1;
}

.servland-projectbox .img-responsive {
transition: transform 0.4s ease-in-out 0s;
width: 100%;
}

.servland-projectbox div {
position: absolute;
bottom: 0;
left: 0;
right: 0;
margin: 0 auto;
padding: 0px 15px 20px;
width: 100%;
z-index: 1;
text-align: center;
}

.servland-projectbox div h4 {
color: #fff;
font-size: 36px;
margin-bottom: 10px;
font-family: "Deluce";
line-height: 1;
text-transform: lowercase;
border-top: 1px solid #fff;
padding-top: 10px;
}

.servland-projectbox div span {
font-size: 11px;
text-transform: uppercase;
font-weight: 500;
letter-spacing: 1.2px;
color: #ffffff;
position: relative;
display: flex;
justify-content: center;
}

.servland-projectbox div span small {
font-size: unset;
}

.servland-projectbox div span small strong{
margin: 0px 4px;
}

.servland-projectbox:hover .img-responsive {
transform: scale(1.05);
}

.servland-curve{
position: absolute;
left: 0;
right: 0;
top: -1.2px;
margin: 0 auto;
z-index: 1;
width: 80px;
}


.servland-process{
margin-top: 60px;
}

.servland-process .heading{
color: #B7AAA4;
}

.servprocess-row{
background: #F2F0EF;
border-radius: 10px;
padding: 30px;
overflow: hidden;
}


.landprocess-accord .card {
border: 0px;
border-radius: 0px;
background: none;
}

.landprocess-accord .card-header {
padding: 0px;
background-color: transparent;
border: 0px;
border-top: 1px solid rgb(183 169 165 / 50%);
border-radius: 0 !important;
}

.landprocess-accord .accordion-item {
background: none;
border: 0px;
}

.landprocess-accord .accordion-body {
padding: 0px 0px 20px;
}

.landprocess-accord .accordion-body p {
margin: 0px;
color: rgb(41 41 29 / 70%);
text-align: justify;
}

.landprocess-accord .accordion-button {
background-color: transparent !important;
box-shadow: none !important;
padding: 18px 0px;
color: #000000 !important;
font-size: 15px;
font-weight: 600;
justify-content: space-between;
text-transform: uppercase;
letter-spacing: 1.2px;
}

.landprocess-accord .accordion-button span {
font-size: 18px;
line-height: 1;
margin-left: 10px;
}

.landprocess-accord .accordion-button label {
cursor: pointer;
width: 90%;
}

.landprocess-accord .accordion-button label strong{
color: #B7A9A5;
margin-right: 30px;
}

.landprocess-accord .accordion-button::after {
display: none;
}


.servland-book{
background: #F2F0EF;
}

.servbook-detail{
background: url(../public/assets/landing/servbook-back.webp) no-repeat;
background-size: cover;
background-position: center;
border-radius: 10px;
padding: 50px;
position: relative;
overflow: hidden;
z-index: 1;
}

.servbook-detail:before{
content: '';
position: absolute;
top: 0;
bottom: 0;
left: 0;
width: 80%;
height: 100%;
background: linear-gradient(to right, #000000, rgb(0 0 0 / 0%) 100%);
z-index: -1;
}

.servbook-detail span{
font-size: 13px;
text-transform: uppercase;
letter-spacing: 1.2px;
color: #B7A9A5;
font-weight: 600;
display: block;
}

.servbook-detail h4{
color: #fff;
text-transform: uppercase;
font-size: 28px;
margin: 5px 0px 40px;
width: 45%;
}

.servland-faq{
padding: 60px 0px;
background: #F2F0EF;
}


.servland-mobilebar{
display: none;
justify-content: space-between;
align-items: center;
background: #000000;
padding: 10px;
position: fixed;
bottom: 0;
left: 0;
right: 0;
width: 100%;
z-index: 8;
}

.servland-mobilebar .default-btn {
padding: 8px 20px;
flex-grow: 1;
}

.mobilebar-icon li {
margin-left: 6px;
display: inline-block;
}

.mobilebar-icon li a {
display: flex;
align-items: center;
justify-content: center;
background: #fff;
border-radius: 4px;
width: 35px;
height: 35px;
transition: all 0.2s ease-In;
}

.mobilebar-icon li a img {
width: 15px;
transition: all 0.2s ease-In;
}

.mobilebar-icon li a:hover {
background: #B7A9A5;
}

.mobilebar-icon li a:hover img {
filter: brightness(0) saturate(100%);
}


.servland-pophead{ 
border-bottom: 1px solid #B7A9A5;
margin-bottom: 25px;
padding-right: 40px;
}

.servland-pophead h4{ 
font-size: 15px;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 1.2px;
color: #000;
margin-bottom: 10px;
}

/*---- main landing ----*/

.mainland-service{
background: #F2F0EF;
padding: 60px 0px; 
}

.landservice-box{
background: #fff;
border-radius: 10px;
padding: 25px 15px;
text-align: center;
}

.landservice-box img {
width: 32px;
}

.landservice-box span {
font-size: 14px;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 1.2px;
display: block;
color: #000;
margin: 18px 0px 0px;
}

.landservice-butn{
text-align: center;
margin-top: 50px;
}


.mainland-project .servland-project {
 background: #000;
}

.mainland-project .servland-project .center-heading {
color: #fff;
}

.mainland-project .servland-projectbox .servland-curve{
-webkit-filter: brightness(0) saturate(100%);
filter: brightness(0) saturate(100%);
}


.mainland-foot .about-footprint {
margin-top: 0px;
background: #F2F0EF;
padding: 50px 0px 40px;
}

.mainland-foot .partner-divider::after {
background: #F2F0EF;
}


.mainland-slider{
position: relative;
}

.mainland-slider::before{
content: '';
position: absolute;
width: 100%;
height: 35%;
left: 0;
right: 0;
bottom: 0;
background: linear-gradient(to top, #000, rgb(0 0 0 / 0%) 100%);
z-index: 1;
}


.mainland-testi{
background: #F2F0EF;
padding-top: 60px;
}

.mainland-counter .home-counter .row {
border: 0px;
padding: 0px;
}

.mainland-counter .homecount-box {
margin: 60px 0px;
}

.mainland-counter .homecount-box p {
font-size: 15px;
color: #B7A9A5;
font-family: Gilroy;
text-transform: uppercase;
font-weight: 500;
letter-spacing: 1px;
}

.mainland-counter .counter-vector-1,
.mainland-counter .counter-vector-2{
display: none;
}


.mainland-space{
background: #F2F0EF;
padding: 60px 0px;
}

.landspace-detail h3 {
color: #000000;
text-transform: uppercase;
font-size: 32px;
font-weight: 600;
margin: 0px;
}

.landspace-detail p {
margin: 15px 0px 0px;
color: rgb(0 0 0 / 60%);
text-align: justify;
}

.landspace-butn{
text-align: right;
}


.mainland-why{
background: #F2F0EF;
padding: 60px 0px;
}

.mainland-why .heading {
color: #B7AAA4;
padding-bottom: 30px;
}

.land-whybox{
text-align: center;
}

.land-whybox p{
font-size: 15px;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 1.2px;
display: block;
color: #000;
margin: 15px 0px 0px;
}

.land-whybox img{
width: 32px;
}


.mainland-about{
background: #F2F0EF;
}

.landabout-image {
position: relative;
padding-right: 40px;
}

.landabout-image .img-responsive {
border-radius: 10px;
}

.landabout-detail img {
width: 32px;
}

.landabout-detail .heading {
text-transform: lowercase;
margin-top: 12px;
}

.landabout-detail p {
margin: 23px 0px 30px;
color: rgb(0 0 0 / 60%);
text-align: justify;
}


.mainheader-icon{
display: flex;
align-items: center;
justify-content: end;
}

.mainheader-icon .default-btn{
padding: 8px 20px;
}

.mainheader-icon ul li {
margin-left: 5px;
display: inline-block;
}

.mainheader-icon ul li a {
display: flex;
align-items: center;
justify-content: center;
background: #fff;
border-radius: 4px;
width: 35px;
height: 35px;
transition: all 0.2s ease-In;
}

.mainheader-icon ul li a img {
width: 15px;
transition: all 0.2s ease-In;
}

.mainheader-icon ul li a:hover {
background: #B7A9A5;
}

.mainheader-icon ul li a:hover img {
filter: brightness(0) saturate(100%);
}

.servland-header.stick .mainheader-icon ul li a {
background: #000;
}

.servland-header.stick .mainheader-icon ul li a:hover {
background: #B7A9A5;
}


/*---- category landing ----*/

.catland-service{
background: #F2F0EF;
padding-top: 60px;
}

.catland-servicehead {
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100%;
padding-left: 31px;
}

.catland-servicehead h2 {
color: #000000;
text-transform: uppercase;
font-size: 32px;
font-weight: 600;
margin: 0px;
}

.catland-servicehead div {
text-align: right;
}

.catland-servicebox{
display: flex;
justify-content: space-between;
flex-wrap: wrap;
}

.catland-servicebox div {
background: #fff;
border-radius: 10px;
padding: 25px;
width: 49%;
margin-bottom: 11px;
}

.catland-servicebox div:nth-child(3),
.catland-servicebox div:nth-child(4){
margin-bottom: 0px;
}

.catland-servicebox div img {
width: 30px;
}

.catland-servicebox div span {
font-size: 14px;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 1.2px;
display: block;
color: #000;
margin: 35px 0px 0px;
}

.catland-servbutn{
display: none;
}


.catland-gallery{
background: linear-gradient(to bottom, #F2F0EF, rgb(255 255 255 / 0%) 90%);
padding-bottom: 60px;
margin-top: 60px;
}

.catland-gallerybox{
display: flex;
flex-wrap: wrap;
justify-content: space-between;
}

.catland-gallerybox .img-responsive{
border-radius: 10px;
width: 49.6%;
margin-bottom: 9px;
}

.catland-gallerybox .img-responsive:nth-child(3),
.catland-gallerybox .img-responsive:nth-child(4){
margin-bottom: 0px;
}


.catland-project{
background: linear-gradient(to top, #F2F0EF, rgb(255 255 255 / 0%) 70%);
margin-top: 55px;
}

.catland-project .projectdetail-big{
margin: 40px 0px;
}


.catland-testi{
background: url(../public/assets/landing/cat-testiback.webp) no-repeat;
background-size: cover;
background-position: center;
padding: 60px 0px;
position: relative;
z-index: 1;
}

.catland-testi:before {
content: '';
position: absolute;
top: 0;
bottom: 0;
left: 0;
width: 80%;
height: 100%;
background: linear-gradient(to right, #000000 20%, rgb(0 0 0 / 0%) 100%);
z-index: -1;
}

.catland-testibox p{
margin: 10px 0px;
color: #fff;
font-size: 22px;
}

.catland-testibox img {
width: 30px;
}

.catland-testibox span {
color: #fff;
font-size: 17px;
display: block;
font-weight: 500;
margin-top: 50px;
}

.catland-testibox small {
color: #B7AAA4;
font-weight: 400;
text-transform: uppercase;
font-size: 12px;
}

.catland-testibox div {
text-align: right;
}

.catland-testibox div img{
transform: rotate(180deg);
}


.catland-getin{
display: flex;
overflow: hidden;
}

.catland-gettext{
background: url(../public/assets/landing/cat-getinback.webp) no-repeat;
background-size: cover;
padding: 60px;
position: relative;
z-index: 1;
width: 60%;
display: flex;
align-items: end;
}

.catland-gettext:before {
content: '';
position: absolute;
top: 0;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
background: rgb(0 0 0 / 40%);
z-index: -1;
}

.catland-gettext .heading{
color: #fff;
}

.catland-getform{
width: 40%;
background: #000;
padding: 46px;
}

.catland-getform .gettouch-form {
margin: 0px auto;
width: 415px;
}

.catland-getform .gettouch-form .default-btn{
width: 100%;
}

.catland-fornhead{
font-size: 18px;
text-transform: uppercase;
font-weight: 600;
letter-spacing: 1px;
color: #000;
margin-bottom: 8px;
}


.catland-banner{
background: #000;
height: 100vh;
position: relative;
}

.catland-banner .container{
transform: translateY(-50%);
top: 50%;
position: relative;
}

.catland-bannertext .heading{
color: #fff;
text-transform: lowercase;
width: 80%;
}

.catland-headerbutn{
display: flex;
align-items: center;
margin-top: 90px;
}

.catland-headerbutn .default-btn {
padding: 8px 20px;
}

.catland-headerbutn ul li {
margin-left: 5px;
display: inline-block;
}

.catland-headerbutn ul li a {
display: flex;
align-items: center;
justify-content: center;
background: #fff;
border-radius: 4px;
width: 35px;
height: 35px;
transition: all 0.2s ease-In;
}

.catland-headerbutn ul li a img {
width: 15px;
transition: all 0.2s ease-In;
}

.catland-headerbutn ul li a:hover {
background: #B7A9A5;
}

.catland-headerbutn ul li a:hover img {
filter: brightness(0) saturate(100%);
}

.catland-bannerform{
background: url(../public/assets/landing/catland-headback.webp) no-repeat;
background-size: cover;
padding: 20px;
position: absolute;
right: 0;
top: 0;
bottom: 0;
height: 100%;
width: 50%;
display: flex;
align-items: center;
justify-content: center;
}

.catland-bannerform .gettouch-form {
margin-left: 0px;
width: 415px;
}




/*-----------------------------------*/


@media (min-width: 1920px) and (max-width: 2560px) {
.catland-gettext { background-position: center;}
.catland-getform { padding: 100px 46px;}
.catland-testi { background-position: bottom; padding: 90px 0px;}
}


@media (min-width: 1801px) and (max-width: 1919px) {
.catland-gettext { background-position: center;}
.catland-getform { padding: 90px 46px;}
.catland-testi { background-position: bottom; padding: 70px 0px;}
}

  
@media (min-width: 1681px) and (max-width: 1800px) {
.catland-gettext { background-position: center;}
.catland-getform { padding: 70px 46px;}
.catland-testi { background-position: bottom; padding: 70px 0px;}
}


@media (min-width: 1600px) and (max-width: 1680px) {
.catland-gettext { background-position: center;}
.catland-getform { padding: 70px 46px;}
.catland-testi { background-position: bottom; padding: 70px 0px;}
}


@media (min-width: 992px) and (max-width: 1200px) {

.servbanner-text .heading { font-size: 62px;}
.servland-banner .gettouch-form .form-feild { padding-left: 12px; padding-right: 12px;}
.serv-servicebox div { padding: 25px 15px;}
.servland-projectbox div { padding: 0px 12px 15px;}
.servland-projectbox div h4 { font-size: 30px; padding-top: 8px; margin-bottom: 8px;}
.servland-projectbox div span { letter-spacing: .8px; justify-content: space-between; flex-wrap: wrap; text-align: left;}
.servland-projectbox div span small { width: 48%; margin-top: 3px;}
.servland-projectbox div span small strong { display: none;}
.servland-projectbox div span small:nth-child(even) { text-align: right;}
.servwhy-box span { font-size: 15px; letter-spacing: .6px;}
.servbook-detail h4 { width: 55%;}

.landservice-box { padding: 25px 12px;}
.landservice-box span { letter-spacing: 1px;}   

.catland-banner .gettouch-form .form-feild { padding-left: 12px; padding-right: 12px;}
.catland-bannertext .heading { font-size: 60px;}
.catland-headerbutn { margin-top: 70px;}
.catland-gettext { width: 50%;}
.catland-getform {width: 50%; padding: 45px;}
.catland-getform .gettouch-form { width: auto;}
.catland-getform .gettouch-form .form-feild { padding-left: 12px; padding-right: 12px;}

}


@media (min-width: 1201px) and (max-width: 1260px) {
.catland-gettext { width: 57%;}
.catland-getform { width: 43%;}

}


@media (min-width: 1200px) and (max-width: 1200px) {

.servbanner-text .heading { font-size: 70px;}
.serv-servicebox div { padding: 25px;}
.servland-projectbox div h4 { border-top: 1px solid #fff; padding-top: 10px; font-size: 36px;}
.servland-projectbox div span { font-size: 11px; justify-content: center; flex-wrap: unset; text-align: center; letter-spacing: 1.2px;}
.servland-projectbox div span small { width: auto; margin-top: 0px;}
.servland-projectbox div span small strong { display: inline-block;}
.servland-projectbox div span small:nth-child(even) { text-align: center;}
.servbook-detail h4 { width: 45%;}
.servwhy-box span { font-size: 16px; letter-spacing: 1.2px;}

.catland-bannertext .heading { font-size: 67px;}
.catland-headerbutn { margin-top: 70px;}
.catland-gettext { width: 60%;}
.catland-getform {width: 40%; padding: 44px;}

}


@media (min-width: 768px) and (max-width: 991px) {

.servland-banner { padding: 120px 0px 60px;}
.servbanner-text .heading { font-size: 56px;}
.servabout-image { padding-right: 0px;}
.servabout-row .row{ align-items: center;}
.servabout-detail .heading { font-size: 60px;}
.servland-counter .homecount-box p { font-size: 14px; letter-spacing: unset;}
.serv-servicebox div { padding: 22px; width: 24%;}
.servtesti-slide p { height: 150px;}
.servland-projectbox div { padding: 0px 10px 15px;}
.servland-projectbox div h4 { font-size: 28px; border-top: 0px; padding-top: 0px; margin-bottom: 8px;}
.servland-projectbox div span { font-size: 10px; letter-spacing: unset; justify-content: space-between; flex-wrap: wrap; text-align: left;}
.servland-projectbox div span small { width: 48%; margin-top: 3px;}
.servland-projectbox div span small strong { display: none;}
.servland-projectbox div span small:nth-child(even) { text-align: right;}
.servbook-detail h4 { width: 72%;}

.mainland-counter .homecount-box h3, .mainland-counter .homecount-box h4{ font-size: 40px;}
.mainland-counter .homecount-box p { font-size: 14px; letter-spacing: unset;}
.mainland-why .heading { padding-bottom: 10px;}
.land-whybox {margin-top: 30px;}
.landabout-image { padding-right: 0px;}
.landabout-detail .heading { font-size: 60px; margin-top: 8px;}
.landabout-detail p { margin: 13px 0px 20px;}
.landabout-detail img { width: 26px;}
.landservice-box { margin-bottom: 24px;}
.landservice-butn { margin-top: 25px;}

.catland-bannertext .heading { width: auto; font-size: 56px;}
.catland-headerbutn { margin-top: 70px;}
.catland-servicehead { padding-left: 0px;}
.catland-servicebox div { width: 48.5%;}
.catland-servicehead h2 { font-size: 28px;}
.catland-gallerybox .img-responsive { width: 49.3%;}
.catland-gettext { background-position: center; padding: 40px; width: 50%;}
.catland-getform { width: 50%; padding: 35px;}
.catland-getform .gettouch-form { width: auto;}


}


@media (min-width: 600px) and (max-width: 767px) {

    .servland-banner { padding: 120px 0px 50px;}
    .servland-banner .gettouch-form { margin-top: 40px;}
    .servland-about { padding: 50px 0px 40px;}
    .servabout-image { padding-right: 0px; margin-bottom: 40px;}
    .servland-counter .home-counter .row { padding: 0;}
    .servland-counter .homecount-box { margin: 18px 0px;}
    .servland-service { padding: 40px 0px 50px;}
    .serv-servicehead h4 { font-size: 28px;}
    .serv-servicebox { flex-wrap: wrap; margin: 30px 0px;}
    .serv-servicebox div { width: 49%; margin: 6px 0px;}
    .serv-service-btn { text-align: left;}
    .servland-why { padding: 50px 0px; background: #F2F0EF;}
    .servland-why .heading { padding-bottom: 20px;}
    .servland-testi{ background: #F2F0EF; padding-bottom: 50px;}
    .servtesti-row { padding: 40px 8px;}
    .servland-process { margin-top: 50px;}
    .landprocess-accord{margin-top: 22px;}
    .landprocess-accord .accordion-button label strong { margin-right: 10px;}
    .servland-project { padding: 45px 0px 50px;}
    .servland-project .center-heading { margin-bottom: 30px;}
    .servland-projectbox { margin-bottom: 25px;}
    .servbook-detail { padding: 25px;}
    .servbook-detail h4 {width: 75%;}
    .servland-faq { padding: 50px 0px;}

    .mainland-counter { margin: 30px 0px;}
    .mainland-counter .homecount-box { margin: 18px 0px;}
    .mainland-counter .homecount-box h3, .mainland-counter .homecount-box h4{ font-size: 40px;}
    .mainland-counter .homecount-box p { font-size: 15px;}
    .mainland-space {padding: 50px 0px;}
    .landspace-detail h3 { font-size: 28px;}
    .landspace-butn { text-align: left; margin-top: 20px;}
    .mainland-why { padding: 50px 0px;}
    .mainland-why .heading { padding-bottom: 0px;}
    .land-whybox { margin-top: 30px;}
    .land-whybox p { font-size: 14px;}
    .landabout-image { padding-right: 0px; margin-bottom: 40px;}
    .mainland-testi { padding-top: 50px;}
    .mainland-testi .servland-testi { padding-bottom: 0px;}
    .mainland-service { padding: 50px 0px;}
    .landservice-box { padding: 25px; margin-bottom: 24px;}
    .landservice-butn { margin-top: 15px;}

    .catland-banner { height: auto; padding-top: 100px;}
    .catland-banner .container { transform: none; position: unset;}
    .catland-bannerform { position: unset; width: 100%; padding: 30px 12px;}
    .catland-headerbutn { margin: 40px 0px 30px;}
    .catland-service { padding-top: 50px;}
    .catland-servicehead { padding-left: 0px;}
    .catland-servicehead h2 { font-size: 28px;}
    .catland-servicehead div { display: none;}
    .catland-servicebox { margin: 30px 0px;}
    .catland-servicebox div { width: 49%;}
    .catland-servbutn {display: block; text-align: center;}
    .catland-gallery { padding-bottom: 50px; margin-top: 50px;}
    .catland-gallerybox .img-responsive { width: 49.1%;}
    .catland-testi { padding: 40px 0px; }
    .catland-testibox p { font-size: 17px;}
    .catland-project { margin-top: 45px;}
    .catland-getin { flex-direction: column;}
    .catland-gettext { background-position: bottom; padding: 240px 20px 20px; width: 100%;}
    .catland-getform { width: 100%; padding: 20px;}
    .catland-getform .gettouch-form { width: auto;}

}


@media (max-width: 599px) {

    .servheader-icon{display: none;}
    .servland-mobilebar { display: flex;}
    .servland-header { padding: 15px 0px;}

    .servland-banner { padding: 100px 0px 50px;}
    .servland-banner .gettouch-form { padding: 70px 30px 30px; margin-top: 40px;}
    .servland-about { padding: 50px 0px 30px;}
    .servabout-detail .default-btn { display: inline-block; margin: 20px auto 0;}
    .servabout-image { padding-right: 0px; margin-bottom: 40px;}
    .servland-counter .home-counter .row { padding: 0;}
    .servland-counter .homecount-box { margin: 18px 0px; text-align: left;}
    .servland-counter .homecount-box h4, .servland-counter .homecount-box h3 {font-size: 32px;}
    .servland-counter .homecount-box p { font-size: 12px;}
    .servland-service { padding: 30px 0px 50px;}
    .serv-servicehead h4 { font-size: 24px;}
    .serv-servicebox { flex-wrap: wrap; margin: 30px 0px;}
    .serv-servicebox div { width: 48.5%; margin: 5px 0px;}
    .serv-service-btn { text-align: left;}
    .servland-why { padding: 50px 0px; background: #F2F0EF;}
    .servland-why .heading { padding-bottom: 20px;}
    .servwhy-box h4 { font-size: 38px;}
    .servland-testi{ background: #F2F0EF; padding-bottom: 50px;}
    .servtesti-row { padding: 40px 8px;}
    .servtesti-slide p { height: 150px;}
    .servland-process { margin-top: 50px;}
    .landprocess-accord{margin-top: 22px;}
    .landprocess-accord .accordion-button label strong { margin-right: 10px;}
    .landprocess-accord .accordion-button { font-size: 14px;}
    .servland-project { padding: 45px 0px 50px;}
    .servland-project .center-heading { margin-bottom: 30px;}
    .servland-projectbox { margin-bottom: 25px;}
    .servland-projectbox div h4 { border-top: 0px; padding-top: 0px;}
    .servbook-detail { padding: 25px;}
    .servbook-detail h4 { font-size: 24px; margin: 5px 0px 60px; width: 75%;}
    .servland-faq { padding: 50px 0px;}
    .servland-touch{ margin-bottom: 54px;}
    .servland-pophead { padding-right: 30px;}

    .mainheader-icon { display: none;}
    .mainland-counter { margin: 30px 0px;}
    .mainland-counter .homecount-box { margin: 18px 0px; text-align: left;}
    .mainland-counter .homecount-box h3, .mainland-counter .homecount-box h4{ font-size: 32px;}
    .mainland-counter .homecount-box p { font-size: 12px;}
    .mainland-space {padding: 50px 0px;}
    .landspace-detail h3 { font-size: 24px;}
    .landspace-butn { text-align: left; margin-top: 20px;}
    .mainland-why { padding: 50px 0px;}
    .mainland-why .heading { padding-bottom: 10px;}
    .land-whybox { margin-top: 25px;}
    .landabout-image { padding-right: 0px; margin-bottom: 40px;}
    .mainland-testi { padding-top: 50px;}
    .mainland-testi .servland-testi { padding-bottom: 0px;}
    .mainland-service { padding: 50px 0px;}
    .landservice-box { padding: 25px; margin-bottom: 24px;}
    .landservice-butn { margin-top: 15px;}

    .catland-banner { height: auto; padding-top: 100px;}
    .catland-banner .container { transform: none; position: unset;}
    .catland-bannerform { position: unset; width: 100%; padding: 30px 12px;}
    .catland-headerbutn { margin: 40px 0px 30px;}
    .catland-service { padding-top: 50px;}
    .catland-servicehead { padding-left: 0px;}
    .catland-servicehead h2 { font-size: 24px;}
    .catland-servicehead div { display: none;}
    .catland-servicebox { margin: 30px 0px;}
    .catland-servicebox div { width: 48.4%;}
    .catland-servbutn {display: block; text-align: center;}
    .catland-gallery { padding-bottom: 50px; margin-top: 50px;}
    .catland-gallerybox .img-responsive { width: 48.8%;}
    .catland-testi { padding: 40px 0px; }
    .catland-testibox p { font-size: 17px;}
    .catland-project { margin-top: 45px;}
    .catland-getin { flex-direction: column; margin-bottom: 54px;}
    .catland-gettext { background-position: center; padding: 200px 20px 20px; width: 100%;}
    .catland-getform { width: 100%; padding: 20px;}
    .catland-getform .gettouch-form { width: auto;}

}