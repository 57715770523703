/* blog listing page */

.bloglist-cards{
  margin-bottom: 60px;
}

.bloglist-card-box{
  position: relative;
  margin-top: 40px;
}

.bloglist-card-box .blog-card-image{
  border-radius: 12px;
  overflow: hidden;
  min-height: 350px;
}

.bloglist-card-box .blog-card-image img{
  transition: transform 0.4s ease-in-out 0s;
  width: 100%;
}

.bloglist-card-box:hover .blog-card-image img{
  transform: scale(1.05);
}

.blog-card-details{
  padding: 15px 15px 0;
}

.blog-card-details span{
  background: rgb(183 170 165 / 10%);
  color: #B7AAA5;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
  display: inline-block;
  padding: 5px 8px;
  border-radius: 5px;
  line-height: 1;
}

.blog-card-details h4{
  font-size: 18px;
  margin: 10px 0;
  font-weight: 600;
  color: #000;
  line-height: 1.4;
}

.blog-card-details label{
  font-size: 11px;
  display: inline-block;
  color: #B7AAA5;
  font-weight: 600;
  text-decoration: underline;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.2s ease-In;
}

.bloglist-card-box:hover .blog-card-details label{
  color: #000;
}

.blogcard-curve{
  position: absolute;
  left: 0;
  right: 0;
  top: -1px;
  margin: 0 auto;
  z-index: 1;
  width: 80px;
}

.load-more-blogs{
  margin-top: 40px;
  text-align: center;
}

/* only css loader */
.loader-only-css{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-only-css .loader-inner-css{
  width: 95px;
  height: 95px;
  background: linear-gradient(to right, #747476 0%, #000000 30%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  animation: loadingSpinner 2s  infinite forwards;
}

.loader-only-css .loader-inner-css div{
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #fff;
}

@keyframes loadingSpinner {
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(180deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
