

/* Existing App styles */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CLS Fixes */

/* Logo sizing fix */
.logo img {
  width: 140px;
  height: auto;
  aspect-ratio: auto;
}

/* Banner image/video fix */
.homebanner-detail .img-responsive,
.homebanner-detail video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

/* General responsive image fix */
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
  aspect-ratio: auto;
}

/* Prevent layout shifts in grid columns */
.col-lg-6,
.col-md-6 {
  min-height: 1px;
}

/* Fix for dynamic content areas */
.buildslide-box,
.whyslide-detail,
.homebanner-detail {
  min-height: 100px;
  overflow: hidden;
}

/* Content area with text */
.col-lg-6 .col-md-6 {
  display: flex;
  flex-direction: column;
}